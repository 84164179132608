import { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styles from './Login.module.css';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';
import LoadingSpinner from '../components/ui/loading';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isLoggedIn, logout, fetchUserData } = useAuth();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      const storedToken = localStorage.getItem('accessToken');
      if (!storedToken) {
        setLoading(false);
        return;
      }

      try {
        await fetchUserData(storedToken);
      } catch (error) {
        console.error('Auth initialization failed:', error);
        logout();
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('emailConfirmed') === 'true') {
      setSuccessMessage('Email confirmed, please login');
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axios.post('auth/login', formData);
      console.log('Login successful:', response.data);
      const token = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      const userData = response.data.user;
      login(token, refreshToken, userData);
      navigate('/map');
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data?.error?.includes('confirm your email')) {
        localStorage.setItem('pendingConfirmationEmailOrUsername', formData.username);
        setError(
          <div>
            {error.response.data.error}
            <Button 
              variant="link" 
              onClick={() => handleResendConfirmation(formData.username)}
              className={styles.resendLink}
            >
              Resend confirmation email
            </Button>
          </div>
        );
      } else {
        setError(error.response?.data?.error || 'An error occurred during login');
      }
    }
  };

  const handleResendConfirmation = async (identifier) => {
    try {
      const storedEmailOrUsername = localStorage.getItem('pendingConfirmationEmailOrUsername');
      
      const isEmail = identifier.includes('@');
      const emailOrUsernameToUse = isEmail ? identifier : storedEmailOrUsername;
      
      if (!emailOrUsernameToUse) {
        setError('Unable to resend confirmation. Please use your email address to log in.');
        return;
      }
      
      await axios.post('auth/resend-confirmation', { usernameOrEmail: emailOrUsernameToUse });
      setError('Confirmation email sent! Please check your inbox.');
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to resend confirmation email. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        {loading && <LoadingSpinner overlay={true} />}
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <h2 className={styles.loginTitle}>Log In</h2>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Username or Email</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" className={styles.loginButton}>
                Log In
              </Button>
            </Form>
            <div className={styles.links}>
            <Button variant="primary" type="submit" className={styles.loginButton}>
                 <Link to="/signup">Don't have an account? Click to sign up</Link>
            </Button>
              <p className={styles.forgotLink}>
                <Link to="/forgot-password">Forgot your password?</Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Login;
