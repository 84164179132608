import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../components/ui/loading';

const HealthCheck = () => {
  const [healthStatus, setHealthStatus] = useState('Checking...');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('health')
      .then((response) => response.json())
      .then((data) => {
        setHealthStatus(data.status);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching health status:', error);
        setHealthStatus('Error');
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <LoadingSpinner overlay={true} />
      ) : (
        <>
          <h1>Health Check</h1>
          <p>Backend Health Status: {healthStatus}</p>
        </>
      )}
    </div>
  );
};

export default HealthCheck;