"use client";

import { forwardRef, useRef } from "react";
import { AnimatedBeam } from "@/components/ui/animatedbeam";
import "./beam.css";
import placeholderImage from '../assets/img/stripe/brick.png'; // Add this import
import ComputerBeamSvg from '../assets/img/landing/computerBeam.svg';
import mapIcon from '../assets/img/landing/map-icon.png';

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={`beam-demo-circle ${className || ''}`}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export default function AnimatedBeamDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const googleDriveRef = useRef<HTMLDivElement>(null);
  const googleDocsRef = useRef<HTMLDivElement>(null);
  const cloudComputingRef = useRef<HTMLDivElement>(null);
  const cnvsdRef = useRef<HTMLDivElement>(null);
  const zapierRef = useRef<HTMLDivElement>(null);
  const webpageRef = useRef<HTMLDivElement>(null);
  const apiRef = useRef<HTMLDivElement>(null);

  return (
    <div className="beam-demo-container" ref={containerRef}>
      <div className="beam-demo-inner">
        <div className="beam-demo-row">
          <Circle ref={googleDriveRef} className="relative left-10 top-5">
            <Icons.googleDrive className="beam-demo-icon" />
          </Circle>
          <Circle ref={googleDocsRef} className="relative right-10 top-10">
            <Icons.googleDocs className="beam-demo-icon" />
          </Circle>
        </div>
        <div className="beam-demo-row">
          <Circle ref={cloudComputingRef} className="relative left-20">
            <Icons.cloudComputing className="beam-demo-icon" />
          </Circle>
          <Circle ref={cnvsdRef} className="beam-demo-circle-large">
            <Icons.cnvsd className="beam-demo-icon" />
          </Circle>
          <Circle ref={zapierRef} className="relative right-20">
            <Icons.zapier className="beam-demo-icon" />
          </Circle>
        </div>
        <div className="beam-demo-row">
          <Circle ref={webpageRef} className="relative left-10 bottom-5">
            <Icons.webpage className="beam-demo-icon" />
          </Circle>
          <Circle ref={apiRef} className="relative right-10 bottom-10">
            <Icons.api className="beam-demo-icon" />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={googleDriveRef}
        toRef={cnvsdRef}
        curvature={-75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={cloudComputingRef}
        toRef={cnvsdRef}
        curvature={10}
        endYOffset={10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={googleDocsRef}
        toRef={cnvsdRef}
        curvature={-75}
        endYOffset={-10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={zapierRef}
        toRef={cnvsdRef}
        curvature={10}
        endYOffset={10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={webpageRef}
        toRef={cnvsdRef}
        curvature={75}
        endYOffset={10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={apiRef}
        toRef={cnvsdRef}
        curvature={75}
        endYOffset={10}
      />
    </div>
  );
}

const Icons = {
  cnvsd: () => (
    <img
      src={placeholderImage}
      alt="Placeholder"
      width="150"
      height="150"
    />
  ),
  googleDrive: () => (
    <img src={ComputerBeamSvg} alt="Computer Beam" width="150" height="150" />
  ),
  cloudComputing: () => (
    <img src={ComputerBeamSvg} alt="Computer Beam" width="150" height="150" />
  ),
  googleDocs: () => (
    <img src={mapIcon} alt="Map Icon" width="150" height="150" />
  ),
  zapier: () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <defs>
        <linearGradient id="tableGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#4CAF50" />
          <stop offset="50%" stopColor="#2196F3" />
          <stop offset="100%" stopColor="#9C27B0" />
        </linearGradient>
      </defs>
      <path
        d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
        stroke="url(#tableGradient)"
      />
    </svg>
  ),
  webpage: () => (
    <img src={ComputerBeamSvg} alt="Computer Beam" width="150" height="150" />
  ),
  api: () => (
    <svg
      width="50"
      height="50"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 16L21 12L17 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 8L3 12L7 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 4L10 20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
};