import { ReactNode } from "react";
import { ArrowRightIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";

const BentoGrid = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "grid w-full grid-cols-5 gap-4 sm:grid-cols-3 lg:grid-cols-5",
        className,
      )}
    >
      {children}
    </div>
  );
};

const BentoCard = ({
  name,
  className,
  Icon,
  description,
  background,
}: {
  name: string;
  className?: string;
  Icon: React.ElementType;
  description: string;
  background?: ReactNode;
}) => (
  <div
    className={cn(
      "group relative flex flex-col justify-between overflow-hidden rounded-xl p-4",
      "bg-white dark:bg-black",
      "border border-neutral-200 dark:border-neutral-800",
      "transition-all duration-300 hover:shadow-xl hover:-translate-y-1",
      className
    )}
  >
    {background}
    <div className="relative z-10 space-y-2">
      <div className="flex flex-col items-center space-y-2">
        <Icon className="text-5xl text-neutral-700 dark:text-neutral-300" />
        <h3 className="text-2xl font-bold text-center text-neutral-900 dark:text-neutral-100">
          {name}
        </h3>
      </div>
      <p className="text-md text-neutral-500 dark:text-neutral-400">{description}</p>
    </div>
  </div>
);

export { BentoCard, BentoGrid };
