import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './LoginButton.module.css';

const LoginButton = () => {
  return (
    <Link to="/login" className={styles.loginLink}>
      <Button variant="primary" className={styles.loginButton}>
        Log In
      </Button>
    </Link>
  );
};

export default LoginButton;
