// MapLayers.js

export const addMapLayers = (map) => {
    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'listings',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });
  
    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'listings',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
  
    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'listings',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#11b4da',
        'circle-radius': 10,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    });
  
    // Add a new source and layer for the search marker
    map.addSource('search-result', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    map.addLayer({
      id: 'search-marker',
      type: 'circle',
      source: 'search-result',
      paint: {
        'circle-radius': 12,
        'circle-color': '#00FF00',//green color for search marker
        'circle-stroke-width': 2,
        'circle-stroke-color': '#FFFFFF'
      }
    });
};

// Add a new helper function to update the search marker
export const updateSearchMarker = (map, coordinates) => {
  if (map.getSource('search-result')) {
    map.getSource('search-result').setData({
      type: 'FeatureCollection',
      features: coordinates ? [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: coordinates
          },
          properties: {}
        }
      ] : []
    });
  }
};
