import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';
import '../Confirmemail/Confirmemail.css';

const PaymentSuccess = () => {
  console.log("Test log");
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('Processing your payment...');
  const { token, refreshUser } = useAuth();

  useEffect(() => {
    if (!token) return;

    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const tier = searchParams.get('tier');
    const type = searchParams.get('type');

    let isUpdating = false;

    const handlePayment = async () => {
      if (isUpdating) return;
      isUpdating = true;

      try {
        if (type === 'one-time') {
          setMessage('Your purchase was successful! Your files are being generated and will be available in your profile.');
        } else if (tier) {
          const response = await axios.post('stripe_subscription/update-user-role', {
            session_id: sessionId,
            tier: tier
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          
          if (response.data.success) {
            console.log('User role updated successfully');
            setMessage('Thank you for your subscription. Your account has been successfully upgraded.');
            await refreshUser();
          } else {
            console.error('Failed to update user role:', response.data.error);
            setMessage('There was an issue updating your account. Please contact support.');
          }
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        setMessage('An error occurred. Please contact support.');
      } finally {
        isUpdating = false;
      }
    };

    handlePayment();

    const timer = setTimeout(() => {
      navigate('/profile');
    }, 5000);

    return () => {
      clearTimeout(timer);
      isUpdating = false;
    };
  }, [token, navigate]);

  return (
    <div className="confirm-email-container">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <p>{message}</p>
    </div>

  );
};

export default PaymentSuccess;
