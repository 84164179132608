import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Remove the access token from localStorage
        localStorage.removeItem('accessToken');

        // Optional: Call logout API endpoint
        await axios.post('auth/logout');

        // Redirect to login page
        navigate('/login');
      } catch (error) {
        console.error('Logout error:', error);
        // Even if the API call fails, redirect to login
        navigate('/login');
      }
    };

    performLogout();
  }, [navigate]);

  return null; // This component doesn't render anything
};

export default Logout;
