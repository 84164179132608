// AuthContext.jsx
import { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from './utils/axios';
import LoadingSpinner from './components/ui/loading';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      const storedToken = localStorage.getItem('accessToken');
      if (!storedToken) {
        setLoading(false);
        return;
      }

      try {
        await fetchUserData(storedToken);
      } catch (error) {
        console.error('Error initializing auth:', error);
        try {
          await refreshToken();
        } catch (refreshError) {
          console.error('Refresh token failed:', refreshError);
          logout();
        }
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  const fetchUserData = async (accessToken) => {
    try {
      const response = await axios.get('auth/user', {
        headers: { 
          'Authorization': `Bearer ${accessToken}`
        }
      });
      setUser(response.data);
      setIsAdmin(response.data.role_id === 4);
      setIsLoggedIn(true);
      setToken(accessToken);
    } catch (error) {
      if (error.response?.status === 401) {
        try {
          const newToken = await refreshToken();
          // Retry the original request with new token
          const retryResponse = await axios.get('auth/user', {
            headers: { 
              'Authorization': `Bearer ${newToken}`
            }
          });
          setUser(retryResponse.data);
          setIsAdmin(retryResponse.data.role_id === 4);
          setIsLoggedIn(true);
          setToken(newToken);
        } catch (refreshError) {
          logout();
          throw refreshError;
        }
      } else {
        throw error;
      }
    }
  };

  const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (!storedRefreshToken) {
      logout();
      throw new Error('No refresh token available');
    }

    try {
      const response = await axios.post('auth/refresh', null, {
        headers: {
          'Authorization': `Bearer ${storedRefreshToken}`
        }
      });
      
      const newAccessToken = response.data.access_token;
      localStorage.setItem('accessToken', newAccessToken);
      setToken(newAccessToken);
      return newAccessToken;
    } catch (error) {
      logout();
      throw new Error('Failed to refresh token');
    }
  };

  const login = async (accessToken, refreshToken, userData) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setIsLoggedIn(true);
    setToken(accessToken);
    setUser(userData);
    setIsAdmin(userData.role_id === 4);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIsLoggedIn(false);
    setToken(null);
    setUser(null);
    setIsAdmin(false);
  };

  const refreshUser = async () => {
    try {
      await fetchUserData(token); // Fetch latest user data
    } catch (error) {
      console.error('Error refreshing user data:', error);
      await refreshToken();
    }
  };

  if (loading) {
    return <LoadingSpinner overlay={true} />;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, user, isAdmin, login, logout, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
