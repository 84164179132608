import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './DemoButton.module.css';

const DemoButton = () => {
  return (
    <Link to="/contact-us" className={styles.demoLink}>
      <Button className={styles.demoButton}>
        Schedule Demo
      </Button>
    </Link>
  );
};

export default DemoButton;
