import { useState } from 'react';
import Navbar from '../Navbar/Navbar.jsx';
import Footer from '../Footer/Footer.jsx';
import SearchPanel from '../SearchPanel/SearchPanel';
import Map from './map.jsx';
import styles from './map_page.module.css';


function MapPage() {
  const [searchResults, setSearchResults] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [showingFavorites, setShowingFavorites] = useState(false);
  const [loading, setLoading] = useState(false);
  const all_results = 'true'; // Always set to true

  const handleSearchResults = (results, params) => {
    setSearchResults(results);
    setSearchParams(params);
    setIsSearchActive(!!results); // Set search as active only when results are not null
    setLoading(false); // Turn off spinner after data loads
    console.log('Search results in MapPage:', results);
    console.log('Search params in MapPage:', params);
  };

  const handleSearchStateChange = (isActive) => {
    setIsSearchActive(isActive);
  };

  const handleReset = () => {
    setSearchResults(null);
    setSearchParams(null);
    setIsSearchActive(false);
  };

  const handleFavoritesToggle = (showFavorites) => {
    setShowingFavorites(showFavorites);
  };

  return (
    <div className={styles.mapPage}>
      <Navbar />
      <main className={styles.mapContent}>
        <div className={styles.searchPanelContainer}>
          <SearchPanel 
            onSearchResults={handleSearchResults}
            onSearchParamsChange={setSearchParams}
            onSearchStateChange={handleSearchStateChange}            
            onReset={handleReset}
            onFavoritesToggle={handleFavoritesToggle}
            setLoading={setLoading} // Pass setLoading to control loading state
            all_results={all_results}
          />
        </div>
        <div className={styles.mapContainer}>
          <Map 
            searchResults={searchResults} 
            searchParams={searchParams} 
            isSearchActive={isSearchActive} 
            onReset={handleReset}
            showingFavorites={showingFavorites}
            setLoading={setLoading} // Pass setLoading to Map
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default MapPage;
