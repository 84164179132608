import { useState, useEffect } from 'react';
import { Container, Alert, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styles from './EmailConfirmation.module.css';

const EmailConfirmation = () => {
  const [status, setStatus] = useState('verifying');
  const [message, setMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const verifyToken = async () => {
    try {
      const response = await axios.get(`auth/confirm/${token}`);
      setStatus('success');
      setMessage(response.data.message);
      setTimeout(() => navigate('/login?emailConfirmed=true'), 3000);
    } catch (error) {
      setStatus('error');
      setMessage(error.response?.data?.error || 'An error occurred');
    }
  };

  const handleResendConfirmation = async () => {
    try {
      const response = await axios.post('auth/resend-confirmation', {
        email: localStorage.getItem('pendingConfirmationEmail')
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || 'Failed to resend confirmation email');
    }
  };

  useEffect(() => {
    verifyToken();
  }, [token]);

  return (
    <>
      <Navbar />
      <Container className={styles.confirmationContainer}>
        {status === 'verifying' && (
          <Alert variant="info">Verifying your email...</Alert>
        )}
        
        {status === 'success' && (
          <Alert variant="success">
            {message}
            <div>Redirecting to login page...</div>
          </Alert>
        )}
        
        {status === 'error' && (
          <Alert variant="danger">
            {message}
            <div className={styles.resendContainer}>
              <Button 
                variant="primary" 
                onClick={handleResendConfirmation}
                className={styles.resendButton}
              >
                Resend Confirmation Email
              </Button>
            </div>
          </Alert>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default EmailConfirmation;