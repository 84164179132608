import { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import styles from './ResetPassword.module.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        setIsLoading(true);
        await axios.get(`auth/reset-password/${token}`);
        setIsTokenValid(true);
      } catch (error) {
        setError('Invalid or expired reset link. Please request a new password reset.');
        setIsTokenValid(false);
      } finally {
        setIsLoading(false);
      }
    };
    verifyToken();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsSubmitting(true);
    setError('');
    setMessage('');

    try {
      const response = await axios.post(`auth/reset-password/${token}`, { password });
      setMessage(response.data.message);
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <Navbar />
        <Container className={styles.loginContainer}>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <h2 className={styles.loginTitle}>Verifying Reset Link...</h2>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }

  if (!isTokenValid) {
    return (
      <>
        <Navbar />
        <Container className={styles.loginContainer}>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <h2 className={styles.loginTitle}>Invalid Reset Link</h2>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Container className={styles.loginContainer}>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h2 className={styles.loginTitle}>Set New Password</h2>
            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button 
                variant="primary" 
                type="submit" 
                className={styles.loginButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Resetting...' : 'Reset Password'}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ResetPassword;