import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CountUp.module.css';
import { motion } from 'framer-motion';
import { useInView } from '../Hooks/useInView';

const CountUp = ({ end, duration, label, formatNumber = true }) => {
  const [count, setCount] = useState(0);
  const [ref, isInView] = useInView({ threshold: 0.5 });

  useEffect(() => {
    let startTime;
    let animationFrame;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);
      const currentCount = Math.floor(percentage * end);

      setCount(currentCount);

      if (percentage < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    if (isInView) {
      animationFrame = requestAnimationFrame(animate);
    }

    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration, isInView]);

  const formattedCount = formatNumber ? count.toLocaleString() : count;

  return (
    <motion.div 
      ref={ref}
      className={styles.countUpContainer}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.count}>{formattedCount}</div>
      <div className={styles.label}>{label}</div>
    </motion.div>
  );
};

CountUp.propTypes = {
    end: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    formatNumber: PropTypes.bool,
};  

export default CountUp;