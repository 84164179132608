// Cards.jsx

import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import styles from './SubscriptionCards.module.css';
import PropTypes from 'prop-types';
import { BiUserCircle } from 'react-icons/bi'; // Free tier icon
import { BsSpeedometer2 } from 'react-icons/bs'; // Monthly/Power tier icon
import { BiBuildings } from 'react-icons/bi'; // Enterprise tier icon
import LoadingSpinner from '../components/ui/loading';

const CheckoutForm = ({ tier, isCurrentPlan, onPlanChange }) => {
  const { isLoggedIn, token, refreshUser } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Handle Enterprise tier differently
    if (tier === 'Enterprise') {
      navigate('/contact-us');
      return;
    }

    if (!isLoggedIn) {
      navigate('/signup');
      return;
    }
    setProcessing(true);

    if (!isLoggedIn || !token) {
      setError("You must be logged in to subscribe.");
      setProcessing(false);
      return;
    }

    // Add check for Administrator selecting Free tier
    if (tier === 'Free' || tier === 'Administrator') {
      // Reuse existing cancel subscription logic
      await handleCancelSubscription();
      return;
    }

    try {
      const response = await axios.post(
        'stripe_subscription/create-checkout-session',
        { tier },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        if (tier === 'Free') {
          console.log('Free subscription activated');
          await refreshUser();
          onPlanChange('Free');
        } else {
          const { error } = await stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
          if (error) {
            setError(error.message);
          }
        }
      } else {
        setError('Failed to create checkout session.');
      }
    } catch (error) {
      console.error('Subscription error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'An error occurred. Please try again.');
    }

    setProcessing(false);
  };

  const handleCancelSubscription = async () => {
    setProcessing(true);
    try {
      const response = await axios.post(
        'stripe_subscription/cancel-subscription',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log('Subscription cancelled successfully');
        await refreshUser();
        onPlanChange('Free');
        navigate('/profile');
      } else {
        setError(response.data.error || 'Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('An error occurred while cancelling the subscription. Please try again.');
    }
    setProcessing(false);
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          <button
            onClick={handleSubmit}
            disabled={processing || isCurrentPlan}
            className={styles.button}
          >
            {isCurrentPlan ? 'Current Plan' : tier === 'Enterprise' ? 'Contact Us' : tier === 'Free' ? 'Select' : 'Sign up'}
          </button>
          {isCurrentPlan && tier !== 'Free' && (
            <button
              onClick={handleCancelSubscription}
              disabled={processing}
              className={`${styles.button} ${styles.cancelButton}`}
            >
              Cancel Subscription
            </button>
          )}
        </>
      ) : (
        <button 
          onClick={handleSubmit} 
          className={styles.button}
        >
          {tier === 'Enterprise' ? 'Contact Us' : 'Sign up'}
        </button>
      )}
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

CheckoutForm.propTypes = {
  tier: PropTypes.string.isRequired,
  isCurrentPlan: PropTypes.bool.isRequired,
  onPlanChange: PropTypes.func.isRequired,
};

const SubscriptionCard = ({
  icon: Icon,
  title,
  subtitle,
  price,
  description,
  isCurrentPlan,
  onPlanChange,
}) => (
  <div className={styles.card}>
    <div className={styles.cardInner}>
      <div className={styles.cardFront}>
        <div className={styles.contentFront}>
          <div className={styles.icon}>
            {typeof Icon === 'string' ? (
              <img src={Icon} alt={`${title} icon`} className={styles.svgIcon} />
            ) : (
              <Icon />
            )}
          </div>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.subtitle}>{subtitle}</p>
          <p className={styles.price}>{price}</p>
        </div>
      </div>
      <div className={styles.cardBack}>
        <div className={styles.contentBack}>
          <h3 className={styles.titleBack}>{title}</h3>
          <p className={styles.description}>{description}</p>
          <div className={styles.buttonContainer}>
            <CheckoutForm
              tier={title}
              isCurrentPlan={isCurrentPlan}
              onPlanChange={onPlanChange}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

SubscriptionCard.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isCurrentPlan: PropTypes.bool.isRequired,
  onPlanChange: PropTypes.func.isRequired,
};

const SubscriptionCards = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const { token, isLoggedIn, user } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await axios.get('stripe_subscription/stripe_key');
        const { publishableKey } = response.data;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error('Error fetching Stripe key:', error);
        setError('Failed to load Stripe. Please try again later.');
      }
    };

    fetchStripeKey();
  }, []);

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (isLoggedIn && token) {
        try {
          const response = await axios.get('stripe_subscription/get-user-plan', {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.data.success) {
            setCurrentPlan(response.data.currentPlan);
          }
        } catch (error) {
          console.error('Error fetching user plan:', error);
        }
      }
    };

    fetchUserPlan();
  }, [user, isLoggedIn, token]);

  const handlePlanChange = (newPlan) => {
    setCurrentPlan(newPlan);
  };

  const subscriptions = [
    {
      icon: BiUserCircle,
      title: 'Free',
      subtitle: 'Best for individuals',
      price: '$0 / month',
      description: `
      • Explore Listings Map
      • Pay Per Record Data Downloads
      • Favorite Listings
      `,
    },
    {
      icon: BsSpeedometer2,
      title: 'Pro',
      subtitle: 'For the Power User',
      price: '$19.99 / month',
      description: `
        • Everything in Free, plus:
        • View Listing Agents & Contact Info
        • Save Queries & Favorite Listings
      `,
    },
    {
      icon: BiBuildings,
      title: 'Enterprise',
      subtitle: 'Organizations',
      price: 'Custom Pricing',
      description: `
        • API Access
        • Pro Accounts For Your Whole Team
        • Share Data Within Your Organization
        • Dedicated Support
      `,
    },
  ];

  if (!stripePromise) {
    return <LoadingSpinner size="large" />;
  }

  return (
    <Elements stripe={stripePromise}>
      <div className={styles.cardContainer}>
        <h2 className="headerTitle">Plans & Pricing</h2>
        {isLoggedIn && currentPlan && (
          <p className={styles.currentPlan}>
            Your current plan: <strong>{currentPlan}</strong>
          </p>
        )}
        <div className={styles.cardsWrapper}>
          {subscriptions.map((sub, index) => (
            <SubscriptionCard
              key={index}
              icon={sub.icon}
              title={sub.title}
              subtitle={sub.subtitle}
              price={sub.price}
              description={sub.description}
              isCurrentPlan={isLoggedIn && currentPlan === sub.title}
              onPlanChange={handlePlanChange}
            />
          ))}
        </div>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </Elements>
  );
};

export default SubscriptionCards;
