import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import axios from '../utils/axios';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth } from '../AuthContext';
import styles from './SearchResultsInfo.module.css';

const SearchResultsInfo = ({ resultCount, searchParams }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const { isLoggedIn, token, user, refreshUser, refreshToken } = useAuth();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await axios.get('stripe_subscription/stripe_key');
        const { publishableKey } = response.data;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error('Error fetching Stripe key:', error);
      }
    };

    fetchStripeKey();
    if (isLoggedIn && !user) {
      refreshUser();
    }
  }, [isLoggedIn, user, refreshUser]);

  const handleDownload = async () => {
    if (!isLoggedIn) {
      alert("Please log in to download data.");
      return;
    }

    try {
      const endpoint = user.role_id === 1 || user.role_id === 4 
        ? 'stripe_subscription/create-free-download'
        : 'stripe_subscription/create-one-time-checkout-session';

      console.log('Making request to:', endpoint, 'with data:', {
        resultCount,
        searchParams,
        fileTypes: ['CSV', 'GeoJSON']
      });

      const response = await axios.post(endpoint, 
        { 
          resultCount, 
          searchParams,
          fileTypes: ['CSV', 'GeoJSON']
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log('Response:', response);

      if (response.data.success) {
        if (user.role_id === 1 || user.role_id === 4) {
          setShowSuccessModal(true);
        } else {
          const { sessionId } = response.data;
          const stripe = await stripePromise;
          if (stripe) {
            await stripe.redirectToCheckout({ sessionId });
          } else {
            console.error('Stripe has not been initialized');
            alert('Payment system is not initialized. Please try again later.');
          }
        }
      } else {
        console.error('Response indicated failure:', response.data);
        alert(response.data.error || 'Failed to process download request');
      }
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        stack: error.stack
      });
      
      if (error.response && error.response.status === 401) {
        try {
          await refreshToken();
          handleDownload();
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          alert('Your session has expired. Please log in again.');
        }
      } else {
        const errorMessage = error.response?.data?.error || 'An error occurred while processing your download.';
        alert(`${errorMessage} Please try again or contact support if the issue persists.`);
      }
    }
  };

  // Determine button text based on user role
  const buttonText = user && (user.role_id === 1 || user.role_id === 4) ? "Free Download" : "Download data";

  // Add pricing data
  const pricingTiers = [
    { range: '0 - 249', price: '$3.75' },
    { range: '250 - 499', price: '$3.50' },
    { range: '500 - 749', price: '$3.25' },
    { range: '750 - 999', price: '$3.00' },
    { range: '1000 - 4999', price: '$2.50' },
    { range: '5000 - 9999', price: '$2.00' },
    { range: '10000 - 19999', price: '$1.50' },
    { range: '20000 - 29999', price: '$1.25' },
    { range: '30000+', price: '$1.00' },
  ];

  return (
    <div className={styles.searchResultsInfoContainer}>
      <span className={styles.resultCount}>{resultCount} results found</span>
      <Button onClick={() => setShowDownloadModal(true)} variant="primary" className={styles.downloadButton}>
        {buttonText}
      </Button>

      <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className={styles.resultCount}>{resultCount} records found - proceed to purchase and export the data.</span>
          
          {/* Add pricing table */}
          <div className="mt-4">
            <h5>Pricing Details</h5>
            <Table striped bordered>
              <thead className="bg-dark text-white">
                <tr>
                  <th>Number of Records</th>
                  <th>Price per Record</th>
                </tr>
              </thead>
              <tbody>
                {pricingTiers.map((tier, index) => (
                  <tr key={index}>
                    <td>{tier.range}</td>
                    <td>{tier.price}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDownloadModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {
            setShowDownloadModal(false);
            handleDownload();
          }}>
            Proceed to Checkout
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal for Free Downloads */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Download Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your download has been processed successfully. You can view your download in your profile page.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

SearchResultsInfo.propTypes = {
  resultCount: PropTypes.number.isRequired,
  searchParams: PropTypes.object,
};

export default SearchResultsInfo;