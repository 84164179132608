import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentCancelled = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/subscription'); // Redirect back to subscription page after 5 seconds
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="container mt-5">
      <div className="alert alert-warning" role="alert">
        <h4 className="alert-heading">Payment Cancelled</h4>
        <p>Your payment process was cancelled. No charges were made to your account.</p>
        <hr />
        <p className="mb-0">You will be redirected back to the subscription page in 5 seconds...</p>
      </div>
    </div>
  );
};

export default PaymentCancelled;