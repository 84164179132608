// mapEvents.js

export const setupMapEventListeners = (map, setPopupInfo, setPopupScreenPosition) => {
  // Change cursor to pointer when hovering over clusters
  map.on('mouseenter', 'clusters', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'clusters', () => {
    map.getCanvas().style.cursor = '';
  });

  // Change cursor to pointer when hovering over unclustered points
  map.on('mouseenter', 'unclustered-point', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'unclustered-point', () => {
    map.getCanvas().style.cursor = '';
  });

  // Click event for unclustered points
  map.on('click', 'unclustered-point', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['unclustered-point'],
    });
    if (features.length > 0) {
      const clickedPoint = features[0];
      const { properties, geometry } = clickedPoint;
      const listing = {
        ...properties,
        broker: properties.broker || 'N/A', // Default value for missing broker
        email: properties.email || 'N/A', // Default value for missing email
        phone: properties.phone || 'N/A', // Default value for missing phone
        brochure_link: properties.brochure_link || 'N/A', // Default value for missing brochure link
        property_type: properties.property_type || 'N/A', // Default value for missing property type
        transaction_type: properties.transaction_type || 'N/A', // Default value for missing transaction type
        max_sq_ft_available: properties.max_sq_ft_available || 'N/A', // Default value for missing max sq ft available
        min_sq_ft_available: properties.min_sq_ft_available || 'N/A', // Default value for missing min sq ft available
        max_ac_available: properties.max_ac_available || 'N/A', // Default value for missing max ac available
        min_ac_available: properties.min_ac_available || 'N/A', // Default value for missing min ac available
        longitude: geometry.coordinates[0],
        latitude: geometry.coordinates[1],
      };
      setPopupInfo({
        listings: [listing],
        currentIndex: 0,
        longitude: geometry.coordinates[0],
        latitude: geometry.coordinates[1],
      });

      // Initialize popup position
      const point = map.project([geometry.coordinates[0], geometry.coordinates[1]]);
      setPopupScreenPosition(point);
    }
  });

  // Click event for clusters
  map.on('click', 'clusters', (e) => {
    const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
    const clusterId = features[0].properties.cluster_id;
    const source = map.getSource('listings');

    source.getClusterLeaves(clusterId, Infinity, 0, (err, clusterFeatures) => {
      if (err) {
        console.error('Error getting cluster leaves:', err);
        return;
      }
      const clusterListings = clusterFeatures.map((f) => ({
        ...f.properties,
        longitude: f.geometry.coordinates[0],
        latitude: f.geometry.coordinates[1],
      }));
      setPopupInfo({
        listings: clusterListings,
        currentIndex: 0,
        longitude: features[0].geometry.coordinates[0],
        latitude: features[0].geometry.coordinates[1],
      });

      // Initialize popup position
      const point = map.project([
        features[0].geometry.coordinates[0],
        features[0].geometry.coordinates[1],
      ]);
      setPopupScreenPosition(point);
    });
  });

  // Add click handler for saved listings
  map.on('click', 'saved-listings', (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: ['saved-listings'],
    });
    if (features.length > 0) {
      const clickedPoint = features[0];
      const { properties, geometry } = clickedPoint;
      const listing = {
        ...properties,
        broker: properties.broker || 'N/A',
        email: properties.email || 'N/A',
        phone: properties.phone || 'N/A',
        brochure_link: properties.brochure_link || 'N/A',
        property_type: properties.property_type || 'N/A',
        transaction_type: properties.transaction_type || 'N/A',
        longitude: geometry.coordinates[0],
        latitude: geometry.coordinates[1],
        isFavorited: true  // Add this line to indicate it's a saved listing
      };
      setPopupInfo({
        listings: [listing],
        currentIndex: 0,
        longitude: geometry.coordinates[0],
        latitude: geometry.coordinates[1],
      });

      // Initialize popup position
      const point = map.project([geometry.coordinates[0], geometry.coordinates[1]]);
      setPopupScreenPosition(point);
    }
  });

  // Add hover effects for saved listings
  map.on('mouseenter', 'saved-listings', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'saved-listings', () => {
    map.getCanvas().style.cursor = '';
  });
};
