import { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import SubscriptionCards from '../LandingPage/SubscriptionCards';
import CompletedPurchasesTable from '../s3bucket_component/S3Bucket';
import styles from './ProfilePage.module.css';
import axios from '../utils/axios';
import LoadingSpinner from '../components/ui/loading';

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(null);
  const [passwordChange, setPasswordChange] = useState({ current: '', new: '', confirm: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const { isLoggedIn, token, logout } = useAuth();
  const navigate = useNavigate();
  const [currentMode, setCurrentMode] = useState('view');
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!isLoggedIn || !token) {
          throw new Error('User is not logged in or token is missing');
        }

        const response = await axios.get('/profile_page/profile', {
          headers: { Authorization: `Bearer ${token}` }
        });

        console.log("Full user data:", response.data);
        setUser(response.data);
        setUpdatedUser(response.data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        console.error('Full error response:', err.response);
        setError(err.response?.data?.message || err.message);
        if (err.message === 'User is not logged in or token is missing') {
          navigate('/login');
        }
        if (err.response?.status === 401) {
          logout();
          navigate('/login');
        }
      }
    };

    fetchUserData();
  }, [isLoggedIn, token, logout, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordChange({ ...passwordChange, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put('/profile_page/update_profile', updatedUser, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setUser(response.data);
      setCurrentMode('view');
      setSuccessMessage('Profile updated successfully');
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (passwordChange.new !== passwordChange.confirm) {
      setError('New passwords do not match');
      return;
    }
    try {
      await axios.put('/profile_page/change_password', {
        current_password: passwordChange.current,
        new_password: passwordChange.new,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setPasswordChange({ current: '', new: '', confirm: '' });
      setSuccessMessage('Password changed successfully');
      setCurrentMode('view');
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      const response = await axios.post('/api/create_api_key', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setApiKey(response.data.api_key);
      setSuccessMessage('API key generated successfully');
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  };

  const handleCancel = () => {
    setCurrentMode('view');
    setError(null);
    setSuccessMessage('');
    setUpdatedUser(user);
    setPasswordChange({ current: '', new: '', confirm: '' });
  };

  if (error) {
    return (
      <>
        <Navbar />
        <Container fluid className={styles.container}>
          <Alert variant="danger">Error: {error}</Alert>
        </Container>
        <Footer />
      </>
    );
  }

  if (!user) {
    return (
      <>
        <Navbar />
        <Container fluid className={`${styles.container} ${styles.loadingContainer}`}>
          <div>Loading...</div>
        </Container>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <Container fluid className={styles.container}>
        {loading && <LoadingSpinner overlay={true} />}
        <Row>
          <Col>
            <Card className={styles.card}>
              <Card.Header as="h2" className={styles.cardHeader}>User Profile</Card.Header>
              <Card.Body className={styles.cardBody}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {currentMode === 'editProfile' ? (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={3}>First Name:</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={updatedUser.first_name}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={3}>Last Name:</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={updatedUser.last_name}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={3}>Email:</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="email"
                          name="email"
                          value={updatedUser.email}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={3}>Company:</Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          name="company_name"
                          value={updatedUser.company_name}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Save Changes</Button>
                    <Button variant="secondary" className="ms-2" onClick={handleCancel}>Cancel</Button>
                  </Form>
                ) : currentMode === 'changePassword' ? (
                  <Form onSubmit={handlePasswordSubmit}>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>Current Password:</Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          name="current"
                          value={passwordChange.current}
                          onChange={handlePasswordChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>New Password:</Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          name="new"
                          value={passwordChange.new}
                          onChange={handlePasswordChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm={4}>Confirm New Password:</Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          name="confirm"
                          value={passwordChange.confirm}
                          onChange={handlePasswordChange}
                          required
                        />
                      </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Change Password</Button>
                    <Button variant="secondary" className="ms-2" onClick={handleCancel}>Cancel</Button>
                  </Form>
                ) : currentMode === 'upgradePlan' ? (
                  <>
                    <SubscriptionCards />
                    <Button variant="secondary" className={styles.backButton} onClick={handleCancel}>Back to Profile</Button>
                  </>
                ) : currentMode === 'viewPurchases' ? (
                  <>
                    <CompletedPurchasesTable />
                    <Button variant="secondary" className={styles.backButton} onClick={handleCancel}>Back to Profile</Button>
                  </>
                ) : (
                  <>
                    <div className={styles.profileInfo}>
                      <Row className={styles.infoRow}>
                        <Col sm={3}><strong>Name:</strong></Col>
                        <Col sm={9}>{`${user.first_name} ${user.last_name}`}</Col>
                      </Row>
                      <Row className={styles.infoRow}>
                        <Col sm={3}><strong>Username:</strong></Col>
                        <Col sm={9}>{user.username}</Col>
                      </Row>
                      <Row className={styles.infoRow}>
                        <Col sm={3}><strong>Email:</strong></Col>
                        <Col sm={9}>{user.email}</Col>
                      </Row>
                      <Row className={styles.infoRow}>
                        <Col sm={3}><strong>Company:</strong></Col>
                        <Col sm={9}>{user.company_name}</Col>
                      </Row>
                      <Row className={styles.infoRow}>
                        <Col sm={3}><strong>Member Since:</strong></Col>
                        <Col sm={9}>{new Date(user.created_at).toLocaleDateString()}</Col>
                      </Row>
                    </div>
                    <div className={styles.buttonGroup}>
                      <Button variant="primary" onClick={() => setCurrentMode('editProfile')}>Edit Profile</Button>
                      <Button variant="secondary" onClick={() => setCurrentMode('changePassword')}>Change Password</Button>
                      <Button variant="success" onClick={() => setCurrentMode('upgradePlan')}>Upgrade Plan</Button>
                      <Button variant="info" onClick={() => setCurrentMode('viewPurchases')}>View Purchases</Button>
                      {user.can_generate_api_key && (
                        <Button variant="warning" onClick={handleGenerateApiKey}>Generate API Key</Button>
                      )}
                    </div>
                    {apiKey && (
                      <div className={styles.apiKeyInfo}>
                        <strong>Your API Key:</strong> {apiKey}
                        <p className={styles.apiKeyWarning}>Please save this key securely. It won't be shown again.</p>
                      </div>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProfilePage;
