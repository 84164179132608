import AnimatedBeamDemo from './beam';
import styles from './WhatIsCanvassed.module.css';

const WhatIsCanvassed = () => {
  return (
    <div id="what-is-canvassed" className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.textContent}>
          <h2 className={styles.title}>What is Canvassed?</h2>
          <p className={styles.description}>
            Canvassed AI is a data platform that delivers high quality real estate data. Our premier listings dataset provides detailed, accurate and updated data for thousands of commercial real estate properties across the US.
            Compiled from individual brokerage houses using proprietary machine learning algorithms desinged to gather the most comprehensive data to transform on your next deal.
          </p>
          <p className={styles.description}>
            With Canvassed, you can streamline your workflow, automate tasks, and unlock new possibilities in your daily operations. Experience the power of connected tools and AI-driven insights, all in one place.
          </p>
        </div>
        <div className={styles.beamDemoWrapper}>
          <AnimatedBeamDemo />
        </div>
      </div>
    </div>
  );
};

export default WhatIsCanvassed;
