import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import styles from './Footer.module.css';

const TOS = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button className={styles.tosButton} onClick={handleShow}>
                Terms of Service
            </button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms of Service</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight: '70vh', overflowY: 'auto'}}>
                    <h6>Last Updated: August 2024</h6>

                    <p>Welcome to Canvassed Inc. By accessing or using our website and services, you agree to be bound by these Terms of Service ("Terms"). Please read these Terms carefully before using our services.</p>

                    <h5>1. Acceptance of Terms</h5>
                    <p>By accessing and using the Canvassed Inc. website (the "Site") and services (collectively, the "Services"), you agree to comply with and be bound by these Terms and any policies referenced herein.</p>

                    <h5>2. Services Provided</h5>
                    <p>Canvassed Inc. provides commercial real estate listings through a web-based platform, which includes a map to view listings, a data portal to download data, and an API product to integrate our data into other applications.</p>

                    <h5>3. Account Creation</h5>
                    <p>To access certain features of our Services, you may need to create an account. You agree to provide accurate and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                    <h5>4. Prohibited Activities</h5>
                    <p>You agree not to engage in any of the following prohibited activities:</p>
                    <ul>
                        <li>Scraping, crawling, or using any automated means to extract data from our Site or Services. All data curated by Canvassed Inc. is strictly protected, and any unauthorized attempt to scrape, harvest, or otherwise extract data is expressly prohibited.</li>
                        <li>Using the Services for any illegal or unauthorized purpose.</li>
                        <li>Interfering with or disrupting the integrity or performance of the Services or the data contained therein.</li>
                        <li>Attempting to gain unauthorized access to the Services or their related systems or networks.</li>
                    </ul>

                    <h5>5. User-Generated Content</h5>
                    <p>If you post or upload content to our Site, you grant Canvassed Inc. a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute such content for the purpose of operating and providing our Services. You represent and warrant that you have all rights necessary to grant this license.</p>

                    <h5>6. Payment Terms</h5>
                    <p>If you purchase any products or services from us, you agree to pay all applicable fees and charges. Payment terms will be disclosed at the time of purchase.</p>

                    <h5>7. Intellectual Property</h5>
                    <p>All content and materials available on the Site, including but not limited to data, text, graphics, website name, code, images, and logos, are the intellectual property of Canvassed Inc. and are protected by applicable copyright and trademark law. Unauthorized use of these materials is strictly prohibited.</p>

                    <h5>8. Limitation of Liability</h5>
                    <p>To the maximum extent permitted by law, Canvassed Inc. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.</p>

                    <h5>9. Governing Law</h5>
                    <p>These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. Any legal actions or proceedings arising out of or related to these Terms or your use of the Services shall be brought exclusively in the federal or state courts located in Delaware, and you consent to the jurisdiction of such courts.</p>

                    <h5>10. Changes to Terms</h5>
                    <p>Canvassed Inc. reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our Site. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.</p>

                    <h5>11. Contact Information</h5>
                    <p>If you have any questions or concerns about these Terms or our Services, please contact us at info@canvassed.ai.</p>

                    <p>By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TOS;
