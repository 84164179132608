import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaMapMarkedAlt, FaPiggyBank } from 'react-icons/fa';
import styles from './features.module.css';
import { useInView } from '../Hooks/useInView';
import databaseIcon from '../assets/img/landing/database-illust.svg';
import mappingIcon from '../assets/img/landing/mapping-platform.svg';
import affordablePricingIcon from '../assets/img/landing/affordable.svg';

const Features = () => {
    const [nextSectionRef, isNextSectionInView] = useInView({ threshold: 0.1 });

    const features = [
        { name: 'Robust Listings Database', 
          description: 'Access a comprehensive database of listings, including detailed property information and contact details.', 
          icon: databaseIcon },
        { name: 'Detailed Mapping Platform', 
          description: 'Utilize our simple to use mapping system to visualize available properties to close your next deal.',
          icon: mappingIcon },
        { name: 'Affordable Pricing', 
          description: 'Sign up for free and get access to our competitive pricing options that meet your budget and needs no matter what size your business is.', 
          icon: affordablePricingIcon },
    ];

    return (
        <>
            <section id="features" className={styles.features}>
                <Container fluid>
                    <Row className={styles.featureRow}>
                        <Col md={5} className={styles.headerColumn}>
                            <div className={`${styles.headerWrapper} ${isNextSectionInView ? styles.hidden : ''}`}>
                                <h2>Features</h2>
                            </div>
                        </Col>
                        <Col md={7} className={styles.cardsColumn}>
                            <div className={styles.cardsContainer}>
                                {features.map((feature, index) => (
                                    <FeatureCard key={index} feature={feature} index={index} />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div ref={nextSectionRef} className={styles.observerTarget}></div>
        </>
    );
};

const FeatureCard = ({ feature, index }) => {
    const [cardRef, isCardInView] = useInView({ threshold: 0.1, triggerOnce: true });

    return (
        <motion.div
            ref={cardRef}
            className={`${styles.featureCard} ${isCardInView ? styles.visible : ''}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isCardInView ? 1 : 0, y: isCardInView ? 0 : 20 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
        >
            <motion.div
                className={styles.featureIcon}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                {typeof feature.icon === 'string' ? 
                    <img src={feature.icon} alt={`${feature.name} icon`} className={styles.svgIcon} /> :
                    <feature.icon className={styles.svgIcon} />
                }
            </motion.div>
            <div className={styles.featureContent}>
                <h3>{feature.name}</h3>
                <p>{feature.description}</p>
            </div>
        </motion.div>
    );
};

FeatureCard.propTypes = {
    feature: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default Features;
