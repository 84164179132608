// Popup.jsx

import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaHeart,
  FaRegHeart,  // Add this import
  FaTimes,
  FaEnvelope,
  FaPhone,
  FaFilePdf,
  FaLink 
} from 'react-icons/fa';
import styles from './Popup.module.css';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const Popup = ({ listings, currentIndex, onClose, onNavigate, onListingUpdate }) => {
  const { token, user } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const popupRef = useRef(null); // Add ref for the popup container

  const [localListings, setLocalListings] = useState(listings);
  const currentListing = localListings[currentIndex];

  const sanitizeData = (data) => {
    if (!data) return '';
    if (Array.isArray(data)) {
      return data[0]; // Take first element if it's an array
    }
    // Remove any square brackets, quotes, and trim whitespace
    return data.toString()
      .replace(/[\[\]']/g, '')  // Remove brackets and single quotes
      .replace(/"/g, '')        // Remove double quotes
      .trim();
  };

  // Update localListings to use sanitized data
  useEffect(() => {
    const cleanedListings = listings.map(listing => ({
      ...listing,
      company_name: sanitizeData(listing.company_name),
      address: sanitizeData(listing.address),
      property_type: sanitizeData(listing.property_type),
      transaction_type: sanitizeData(listing.transaction_type),
      max_sq_ft_available: sanitizeData(listing.max_sq_ft_available),
      min_sq_ft_available: sanitizeData(listing.min_sq_ft_available),
      max_ac_available: sanitizeData(listing.max_ac_available),
      min_ac_available: sanitizeData(listing.min_ac_available),
      broker: sanitizeData(listing.broker),
      email: sanitizeData(listing.email),
      phone: sanitizeData(listing.phone),
    }));
    setLocalListings(cleanedListings);
  }, [listings]);

  const toggleFavoriteMutation = useMutation(
    (hash_id) => {
      return axios.post(
        '/api/saved_items/toggle_favorite',
        { hash_id },
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedListings');
        const updatedListing = {
          ...currentListing,
          isFavorited: !currentListing.isFavorited
        };
        
        // Update local state
        setLocalListings((prevListings) => {
          const updatedListings = [...prevListings];
          updatedListings[currentIndex] = updatedListing;
          return updatedListings;
        });

        // Update map markers
        onListingUpdate(updatedListing, !currentListing.isFavorited);
      },
      onError: (error) => {
        console.error('Error toggling favorite:', error.response?.data || error.message);
      },
    }
  );

  const handleFavoriteClick = () => {
    if (!token) {
      console.log('User is not authenticated.');
      return;
    }
    toggleFavoriteMutation.mutate(currentListing.hash_id);
  };

  // Check if user can view sensitive information
  const canViewSensitiveInfo = user && user.role_id !== 3;
  const shouldShowUpgradeButton = user && user.role_id === 3;

  const handleUpgrade = () => {
    navigate('/profile', { state: { initialMode: 'upgradePlan' } });
    onClose();
  };

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={styles.popup} ref={popupRef}>
      <div className={`${styles.popupHeader} ${!canViewSensitiveInfo ? styles.noCompanyName : ''}`}>
        {canViewSensitiveInfo && <h4>{currentListing.company_name}</h4>}
        <button className={styles.popupClose} onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      <div className={styles.popupContent}>
        {canViewSensitiveInfo && (
          <p>
            <strong>Address:</strong> {currentListing.address}
          </p>
        )}
        <p>
          <strong>Property Type:</strong> {currentListing.property_type}
        </p>
        <p>
          <strong>Transaction Type:</strong> {currentListing.transaction_type}
        </p>
        <p>
          <strong>Max Sq Ft:</strong> {currentListing.max_sq_ft_available}
        </p>
        <p>
          <strong>Min Sq Ft:</strong> {currentListing.min_sq_ft_available}
        </p>
        <p>
          <strong>Max Acres:</strong> {currentListing.max_ac_available}
        </p>
        <p>
          <strong>Min Acres:</strong> {currentListing.min_ac_available}
        </p>
        {canViewSensitiveInfo ? (
          <>
            <p>
              <strong>Broker:</strong> {currentListing.broker}
            </p>
            <div className={styles.contactInfo}>
              <p className={styles.longText}>
                <FaEnvelope /> {currentListing.email}
              </p>
              <p className={styles.longText}>
                <FaPhone /> {currentListing.phone}
              </p>
            </div>
            <p>
              <a href={currentListing.brochure_link} target="_blank" rel="noopener noreferrer">
                <FaFilePdf /> Brochure
              </a>
            </p>
            <p>
              <a href={currentListing.link} target="_blank" rel="noopener noreferrer">
                <FaLink /> View Listing
              </a>
            </p>
          </>
        ) : (
          <p>Upgrade your account to view company name, broker, and contact information.</p>
        )}

      </div>
      <div className={styles.popupFooter}>
        {/* Navigation buttons */}
        {localListings.length > 1 && (
          <div className={styles.navigation}>
            <button onClick={() => onNavigate('prev')} disabled={currentIndex === 0}>
              <FaArrowLeft />
            </button>
            <span>
              {currentIndex + 1} / {localListings.length}
            </span>
            <button
              onClick={() => onNavigate('next')}
              disabled={currentIndex === localListings.length - 1}
            >
              <FaArrowRight />
            </button>
          </div>
        )}
        {/* Action buttons */}
        <div className={styles.actionButtons}>
          <button
            className={`${styles.popupFavorite} ${
              currentListing.isFavorited ? styles.favorited : ''
            }`}
            onClick={handleFavoriteClick}
          >
            {currentListing.isFavorited ? <FaHeart /> : <FaRegHeart />}
          </button>
          {shouldShowUpgradeButton && (
            <button className={styles.popupUpgrade} onClick={handleUpgrade}>
              Upgrade
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      hash_id: PropTypes.string.isRequired,
      company_name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
      transaction_type: PropTypes.string.isRequired,
      max_sq_ft_available: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number,
      ]),
      broker: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      brochure_link: PropTypes.string,
      isFavorited: PropTypes.bool,
    })
  ).isRequired,
  currentIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onNavigate: PropTypes.func,
  onListingUpdate: PropTypes.func.isRequired,
  // Remove onUpgrade from prop types
};

export default Popup;
