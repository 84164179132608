import React, { useState, useEffect } from 'react';
import axios from '../utils/axios';
import Navbar from '../Navbar/Navbar.jsx';
import Footer from '../Footer/Footer.jsx';
import SearchPanel from '../SearchPanel/SearchPanel';
import Dataportal from './Dataportal.jsx';
import SearchResultsInfo from './SearchResultsInfo.jsx';
import CustomPagination from './Pagination.jsx';
import styles from './DataportalContainer.module.css';
import mapStyles from '../Map/map_page.module.css';
import LoadingSpinner from '../components/ui/loading.jsx';
import { useAuth } from '../AuthContext';


function DataPortalContainer() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [lastSearchParams, setLastSearchParams] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const [currentSearchParams, setCurrentSearchParams] = useState(null);

  const { token, user } = useAuth();

  useEffect(() => {
    if (!isSearchActive) {
      fetchData(currentPage);
    } else if (lastSearchParams) {
      performSearch({ ...lastSearchParams, page: currentPage });
    }
  }, [currentPage, isSearchActive]);

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`search_controls/dataportal?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setData(response.data.listings);
      setTotalPages(response.data.pages);
      setCurrentPage(response.data.current_page);
      setTotalResults(response.data.total); // Set total results for initial load
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      console.error('Error fetching data:', error);
      console.error('Request URL:', error.config?.url);
      console.error('Request baseURL:', error.config?.baseURL);
      setError('Failed to load data');
      setLoading(false);
    }
  };

  const handleSearchResults = (results, searchParams) => {
    console.log('Search results:', results);
    setData(results.listings);
    setTotalPages(results.pages);
    setCurrentPage(results.current_page);
    setTotalResults(results.total);
    setLoading(false);
    setError(null);
    setIsSearchActive(true);
    setLastSearchParams(searchParams);
    setCurrentSearchParams(searchParams);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (isSearchActive && lastSearchParams) {
      performSearch({ ...lastSearchParams, page: newPage });
    } else {
      fetchData(newPage);
    }
  };

  const performSearch = async (searchParams) => {
    setLoading(true);
    try {
      console.log('Performing search with params:', searchParams);
      const response = await axios.get('search_controls/search', { 
        params: { ...searchParams, per_page: 10, page: searchParams.page || 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Search API response:', response.data);
      handleSearchResults(response.data, searchParams);
    } catch (error) {
      console.error('Error performing search:', error);
      setError('Failed to perform search');
      setLoading(false);
    }
  };

  const handleApplySavedSearch = async (searchCriteria) => {
    setCurrentPage(1); // Reset to first page when applying a saved search
    await performSearch({ ...searchCriteria, page: 1 });
  };

  const handleSearchParamsChange = (searchParams) => {
    setCurrentSearchParams(searchParams);
  };

  const handleReset = () => {
    setData([]);
    setIsSearchActive(false);
    setCurrentPage(1);
    setTotalPages(1);
    setTotalResults(0);
    setCurrentSearchParams(null);
    fetchData(1);
  };

  return (
    <div className={styles.dataportalWrapper}>
      {loading && <LoadingSpinner overlay={true} />}
      {error && <div className="error-message">{error}</div>}
      <div className={styles.tableWrapper}>
        <div className={styles.dataportalContainer}>
          <Navbar />
          <main className={styles.dataportalContent}>
            <div className={styles.contentLayout}>
              <aside className={styles.searchPanelContainer}>
                <SearchPanel 
                  onSearchResults={handleSearchResults} 
                  onApplySavedSearch={handleApplySavedSearch}
                  onSearchParamsChange={handleSearchParamsChange}
                  hideViewFavorites={true}
                  onReset={handleReset}
                />
              </aside>
              
              <div className={styles.dataContent}>
                <h2>Data Table</h2>
                {!loading && !error && data.length > 0 && (
                  <div className={styles.headerControls}>
                    <CustomPagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                    <SearchResultsInfo 
                      resultCount={totalResults} 
                      searchParams={currentSearchParams}
                    />
                  </div>
                )}
                
                <Dataportal 
                  data={data} 
                  loading={loading} 
                  error={error}
                  userRole={user?.role_id}
                />
                {!loading && !error && data.length > 0 && (
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default DataPortalContainer;