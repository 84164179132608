// Map.jsx

import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Popup from '../Popup/Popup.jsx';
import { updateListings, fitMapToSearchResults, updateSingleListing } from './mapUtils';
import { MOVE_END_DELAY } from './mapConstants';
import { addMapLayers, updateSearchMarker } from './mapLayers';
import { setupMapEventListeners } from './mapEvents';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styles from '../Popup/Popup.module.css';
import LoadingSpinner from '../components/ui/loading';

mapboxgl.accessToken = 'pk.eyJ1IjoiamVyZW15c2luZ2gyMSIsImEiOiJja3I2aWU2cXcydDBxMnFxdTd0YWl0dDlkIn0.yWYID-8FaI5HZ5DmV66PPw';

const Map = ({ searchResults, searchParams, isSearchActive, onReset, showingFavorites, setLoading}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-74.006);
  const [lat, setLat] = useState(40.7128);
  const [zoom, setZoom] = useState(12);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [popupScreenPosition, setPopupScreenPosition] = useState(null);

  const lastMoveTimestamp = useRef(0);
  const moveEndTimer = useRef(null);
  const { isLoggedIn, token } = useAuth();

  const [savedListings, setSavedListings] = useState([]);
  const isSearchActiveRef = useRef(isSearchActive);
  useEffect(() => {
    isSearchActiveRef.current = isSearchActive;
  }, [isSearchActive]);

  const [isLoading, setIsLoading] = useState(false);

  const fetchSavedListings = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('saved_items/get_saved_listings', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSavedListings(response.data);
    } catch (error) {
      console.error('Error fetching saved listings:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoggedIn, token]);

  const handleMoveEnd = () => {
    console.log('Map move ended');
    if (moveEndTimer.current) {
      clearTimeout(moveEndTimer.current);
    }
    moveEndTimer.current = setTimeout(() => {
      const currentTime = Date.now();
      if (
        currentTime - lastMoveTimestamp.current > MOVE_END_DELAY &&
        !isSearchActiveRef.current &&
        mapLoadedRef.current
      ) {
        console.log('Fetching listings after move');
        fetchListings();
        fetchSavedListings();
      } else {
        console.log('Skipping fetch due to recent movement, active search, or map not loaded');
      }
    }, MOVE_END_DELAY);
    lastMoveTimestamp.current = Date.now();
  };
  const mapLoadedRef = useRef(false);
  const setMapLoadedState = (value) => {
    setMapLoaded(value);
    mapLoadedRef.current = value;
  };

  useEffect(() => {
    if (map.current) return;
    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom,
      });

        // Add the geocoder control
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false, // Keep this false as we're using our custom marker
          placeholder: 'Search for a location',
          bbox: [-74.259087, 40.477398, -73.700172, 40.917576], // Bound to NYC area
          proximity: {
            longitude: lng,
            latitude: lat
          }
        });
  
        map.current.addControl(geocoder);
  
        // Handle the result
        geocoder.on('result', (e) => {
          const coordinates = e.result.center;
          
          // Update the search marker
          updateSearchMarker(map.current, coordinates);

          // Fly to the location
          map.current.flyTo({
            center: coordinates,
            zoom: 14
          });
        });

        // Clear the marker when the search is cleared
        geocoder.on('clear', () => {
          updateSearchMarker(map.current, null);
        });
      // Add zoom and rotation controls to the map.
      map.current.addControl(new mapboxgl.NavigationControl());



      map.current.on('load', () => {
        console.log('Map loaded');
        setLoading(false);
        setMapLoadedState(true);

        map.current.addSource('listings', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [],
          },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });
        
        addMapLayers(map.current);
        setupMapEventListeners(map.current, setPopupInfo, setPopupScreenPosition);

        //fetchListings();
        fetchSavedListings().then(() => {
          if (map.current.getSource('listings')) {
            updateListings(map.current, [], savedListings);
          }
        });
      });

      map.current.on('movestart', () => {
        console.log('Map move started');
      });

      map.current.on('moveend', handleMoveEnd);

      map.current.on('idle', () => {
        if (!mapLoadedRef.current) {
          console.log('Map idle - considering map loaded');
          setMapLoadedState(true);
          fetchListings();
        }
      });
    } catch (error) {
      console.error('Error initializing map:', error);
      setLoading(false);
    }
  }, [handleMoveEnd, lat, lng, zoom, fetchSavedListings]);

  useEffect(() => {
    if (map.current && map.current.getSource('listings') && mapLoadedRef.current) {
      if (searchResults && searchResults.listings && searchResults.listings.length > 0) {
        console.log('Updating map with search results:', searchResults.listings);
        updateListings(map.current, searchResults.listings, savedListings);
        fitMapToSearchResults(map.current, searchResults.listings, searchParams);
        setLoading(false);
      } else if (!isSearchActive) {
        console.log('No search active, fetching listings for current view');
        fetchListings();
      }
    }
  }, [searchResults, searchParams, savedListings, isSearchActive]);

  const fetchListings = async () => {
    console.log(
      'fetchListings called. Map current:',
      !!map.current,
      'Map loaded:',
      mapLoadedRef.current,
      'isSearchActive:',
      isSearchActive
    );
    if (!map.current || !mapLoadedRef.current) {
      console.log('Map not ready, skipping fetchListings');
      return;
    }

    const bounds = map.current.getBounds();
    console.log('Fetching listings for bounds:', bounds.toString());

    try {
      console.log('Sending request to API...');
      const response = await axios.get(
        `map/listings?ne_lat=${bounds.getNorthEast().lat}&ne_lng=${bounds.getNorthEast().lng}&sw_lat=${bounds.getSouthWest().lat}&sw_lng=${bounds.getSouthWest().lng}`
      );

      console.log('Received response from API');
      const data = response.data;

      console.log('Data received from Flask route:', data);
      if (data.listings && Array.isArray(data.listings)) {
        console.log('Updating listings with fetched data:', data.listings.length, 'listings');
        updateListings(map.current, data.listings, savedListings);
      } else {
        console.error('Invalid listings data:', data);
      }
    } catch (error) {
      console.error('Error in fetchListings:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!map.current) return;

    const updatePopupPosition = () => {
      if (popupInfo) {
        const point = map.current.project([popupInfo.longitude, popupInfo.latitude]);
        setPopupScreenPosition(point);
      }
    };

    // Update position on map move
    map.current.on('move', updatePopupPosition);

    // Cleanup on unmount
    return () => {
      if (map.current) {
        map.current.off('move', updatePopupPosition);
      }
    };
  }, [popupInfo]);

  const handleClosePopup = () => {
    setPopupInfo(null);
    setPopupScreenPosition(null); // Reset position when popup is closed
  };

  const handleUpgrade = (id) => {
    console.log(`Upgrade listing with id: ${id}`);
  };

  const handleNavigate = (direction) => {
    setPopupInfo((prev) => {
      const newIndex =
        direction === 'next'
          ? Math.min(prev.currentIndex + 1, prev.listings.length - 1)
          : Math.max(prev.currentIndex - 1, 0);
      return { ...prev, currentIndex: newIndex };
    });
  };

  useEffect(() => {
    if (!map.current || !mapLoaded) return;

    const toggleLayerVisibility = () => {
      try {
        if (showingFavorites) {
          map.current.setLayoutProperty('clusters', 'visibility', 'none');
          map.current.setLayoutProperty('cluster-count', 'visibility', 'none');
          map.current.setLayoutProperty('unclustered-point', 'visibility', 'none');
          map.current.setLayoutProperty('saved-listings', 'visibility', 'visible');
        } else {
          map.current.setLayoutProperty('clusters', 'visibility', 'visible');
          map.current.setLayoutProperty('cluster-count', 'visibility', 'visible');
          map.current.setLayoutProperty('unclustered-point', 'visibility', 'visible');
          map.current.setLayoutProperty('saved-listings', 'visibility', 'visible');
        }
      } catch (error) {
        console.error('Error toggling layer visibility:', error);
      }
    };

    // Only attempt to toggle visibility after style is loaded
    if (map.current.isStyleLoaded()) {
      toggleLayerVisibility();
    } else {
      map.current.once('style.load', toggleLayerVisibility);
    }
  }, [showingFavorites, mapLoaded]);

  const handleListingUpdate = useCallback((listing, isSaved) => {
    if (map.current) {
      updateSingleListing(map.current, listing, isSaved);
    }
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {isLoading && <LoadingSpinner overlay={true} />}
      <div
        className="sidebar"
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          zIndex: 1,
          background: 'rgba(255,255,255,0.7)',
          padding: '10px',
          borderRadius: '4px',
        }}
      >
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div
        ref={mapContainer}
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      />
      {popupInfo && (() => {
        const mapContainerBounds = mapContainer.current.getBoundingClientRect();
        
        // Use popupScreenPosition if available
        const point = popupScreenPosition
          ? popupScreenPosition
          : map.current.project([popupInfo.longitude, popupInfo.latitude]);

        // Get actual popup dimensions after render
        const popupElement = document.querySelector(`.${styles.popup}`);
        const popupWidth = popupElement ? popupElement.offsetWidth : 220;
        const popupHeight = popupElement ? popupElement.offsetHeight : 300;

        let x = point.x;
        let y = point.y;

        // Adjust x to prevent horizontal overflow
        if (x + popupWidth > mapContainerBounds.width) {
          x = mapContainerBounds.width - popupWidth - 10;
        }
        x = Math.max(10, x); // Prevent negative x

        // Adjust y position
        y = y - popupHeight - 10; // Try positioning above first
        if (y < 10) { // If too close to top
          y = point.y + 10; // Position below the point
          // Check if it would overflow at bottom
          if (y + popupHeight > mapContainerBounds.height) {
            y = mapContainerBounds.height - popupHeight - 10;
          }
        }

        return (
          <div
            style={{
              position: 'absolute',
              left: `${x}px`,
              top: `${y}px`,
              zIndex: 2,
            }}
          >
            <Popup
              listings={popupInfo.listings}
              currentIndex={popupInfo.currentIndex}
              onClose={handleClosePopup}
              onNavigate={handleNavigate}
              onListingUpdate={handleListingUpdate}
            />
          </div>
        );
      })()}
    </div>
  );
};

Map.propTypes = {
  searchResults: PropTypes.shape({
    listings: PropTypes.arrayOf(
      PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
        hash_id: PropTypes.string,
        company_name: PropTypes.string,
        address: PropTypes.string,
        property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        transaction_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        max_sq_ft_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        broker: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        brochure_link: PropTypes.string,
        isFavorited: PropTypes.bool,
      })
    ),
    searchParams: PropTypes.object,
  }),
  searchParams: PropTypes.object,
  isSearchActive: PropTypes.bool,
  showingFavorites: PropTypes.bool,
};

export default Map;
