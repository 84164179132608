import './Hero.css'; // We'll create this file for styling 
import placeholderImage from '../assets/img/stripe/brick.png'; // Update this path
import { motion } from 'framer-motion';
import SignUpButton from '../Buttons/SignUpButton';
import LoginButton from '../Buttons/LoginButton';
import DemoButton from '../Buttons/DemoButton';
import { useAuth } from '../AuthContext';

const Hero = () => {
  const { isLoggedIn } = useAuth();

  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <motion.div 
            className="hero-image"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img src={placeholderImage} alt="Geometric shape" className="placeholder-image" />
          </motion.div>
          <div className="hero-text">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Looking for space?
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              Consider it Canvassed.
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Commercial real estate listings data on demand.
            </motion.p>
            {!isLoggedIn && (
              <div className="button-container">
                <div className="button-wrapper"><LoginButton /></div>
                <div className="button-wrapper"><SignUpButton /></div>
                <div className="button-wrapper"><DemoButton /></div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
