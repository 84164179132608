import { motion } from 'framer-motion';
import styles from './css/loading.module.css';

const LoadingSpinner = ({ size = 'medium', overlay = false }) => {
  const spinnerSizes = {
    small: '24px',
    medium: '40px',
    large: '56px'
  };

  const spinnerComponent = (
    <motion.div
      className={styles.spinner}
      style={{ width: spinnerSizes[size], height: spinnerSizes[size] }}
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
    />
  );

  if (overlay) {
    return (
      <div className={styles.overlay}>
        {spinnerComponent}
      </div>
    );
  }

  return spinnerComponent;
};

export default LoadingSpinner;