import { useNavigate } from 'react-router-dom';
import styles from './Footer.module.css';
import TOS from './TOS';

const Footer = () => {
    const navigate = useNavigate();

    const handleAboutClick = (event) => {
        event.preventDefault();
        navigate('/?scrollTo=what-is-canvassed');
    };

    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.logo}>
                    <img src="https://cnvsdlogos.s3.amazonaws.com/standard_logo_files/original_on_transparent_pink.png" alt="Canvassed Logo" />
                </div>
                <nav className={styles.footerNav}>
                    <ul>
                        <li><a href="#what-is-canvassed" onClick={handleAboutClick}>About</a></li>
                        <li><a href="/contact-us">Contact</a></li>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><TOS /></li>
                    </ul>
                </nav>
                <div className={styles.socialLinks}>
                    <a href="https://twitter.com/canvassed" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/canvassed-ai/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>
            <div className={styles.copyright}>
                © {new Date().getFullYear()} Canvassed. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
