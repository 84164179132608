import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/img/stripe/cnvsd.png';
import { useAuth } from '../AuthContext';

const Navbar = () => {
  const { isLoggedIn, logout, isAdmin } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu when a link is clicked (on mobile)
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo" onClick={closeMenu}>
          <img src={logo} alt="Company Logo" />
        </NavLink>

        <button
          className={`navbar-toggler ${isOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle navigation"
          aria-expanded={isOpen}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
          <NavLink to="/" className="nav-link" onClick={closeMenu}>
            Home
          </NavLink>
          {isLoggedIn && (
            <>
              <NavLink to="/map" className="nav-link" onClick={closeMenu}>
                Listings Map <i className="bi bi-map"></i>
              </NavLink>
              <NavLink to="/data-portal" className="nav-link" onClick={closeMenu}>
                Data Portal <i className="bi bi-database-down"></i>
              </NavLink>
            </>
          )}
          {isAdmin && (
            <NavLink to="/admin" className="nav-link" onClick={closeMenu}>
              Admin Dashboard <i className="bi bi-gear"></i>
            </NavLink>
          )}
          {isLoggedIn && (
            <NavLink to="/profile" className="nav-link" onClick={closeMenu}>
              Profile <i className="bi bi-person-badge"></i>
            </NavLink>
          )}
          <NavLink to="/contact-us" className="nav-link" onClick={closeMenu}>
            Contact Us <i className="bi bi-envelope"></i>
          </NavLink>
          {isLoggedIn ? (
            <NavLink
              to="/"
              onClick={() => {
                logout();
                closeMenu();
              }}
              className="nav-link"
            >
              Log Out <i className="bi bi-box-arrow-right"></i>
            </NavLink>
          ) : (
            <>
              <NavLink to="/login" className="nav-link" onClick={closeMenu}>
                Log In <i className="bi bi-box-arrow-left"></i>
              </NavLink>
              <NavLink to="/signup" className="nav-link" onClick={closeMenu}>
                Sign Up <i className="bi bi-check2-square"></i>
              </NavLink>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
