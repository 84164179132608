"use client";

import { cn } from "@/lib/utils";
import { DotPattern } from "@/components/ui/dotpattern";

export function DotPatternDemo({ className }: { className?: string }) {
  return (
    <div className={cn("relative flex flex-col items-center justify-center overflow-hidden", className)}>
      <DotPattern
        className={cn(
          "[mask-image:radial-gradient(100% 100% at center,red,transparent)]",
          "opacity-50"
        )}
      />
    </div>
  );
}
