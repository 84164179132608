import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaTable, FaCode, FaShoppingCart } from 'react-icons/fa';
import styles from './services.module.css';
import { useInView } from '../Hooks/useInView';
import dataPortalIcon from '../assets/img/landing/data-portal.svg';
import apiAccessIcon from '../assets/img/landing/api-service.svg';
import dataDemandIcon from '../assets/img/landing/cart.svg';

const Services = () => {
    const [nextSectionRef, isNextSectionInView] = useInView({ threshold: 0.1 });

    const services = [
        { name: 'Data Portal', description: 'We provide a data portal for the more data driven users to access data in a more structured way.', 
            icon: dataPortalIcon },
        { name: 'API Access', 
            description: 'Robust API access for the more technical users and teams to ingest our data into their own systems.', 
            icon: apiAccessIcon },
        { name: 'Data on Demand', 
            description: 'Buy only what you need, when you need it with a la carte or bulk options.', 
            icon: dataDemandIcon },
      ];

    return (
        <>
            <section id="services" className={styles.services}>
                <Container fluid>
                    <Row className={styles.serviceRow}>
                        <Col xs={12} className={styles.headerColumn}>
                            <div className={`${styles.headerWrapper} ${isNextSectionInView ? styles.hidden : ''}`}>
                                <h2>Services</h2>
                            </div>
                        </Col>
                        <Col xs={12} className={styles.cardsColumn}>
                            <div className={styles.cardsContainer}>
                                {services.map((service, index) => (
                                    <motion.div
                                        key={index}
                                        className={styles.serviceCard}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                    >
                                        <motion.div
                                            className={styles.serviceIcon}
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.9 }}
                                        >
                                            {typeof service.icon === 'string' ? 
                                                <img src={service.icon} alt={`${service.name} icon`} className={styles.svgIcon} /> :
                                                <service.icon className={styles.svgIcon} />
                                            }
                                        </motion.div>
                                        <div className={styles.serviceContent}>
                                            <h3>{service.name}</h3>
                                            <p>{service.description}</p>
                                        </div>
                                    </motion.div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div ref={nextSectionRef} className={styles.observerTarget}></div>
        </>
    );
};

Services.propTypes = {
    services: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
    })).isRequired,
};

export default Services;
