import { FaBuilding, FaChartLine, FaMoneyBillWave } from 'react-icons/fa';
import { BentoCard, BentoGrid } from "@/components/ui/bentogrid";
import styles from './bento.module.css'; // Import the CSS module
import municipalitiesIcon from '@/assets/img/landing/municipalities.svg';
import brokerageHousesIcon from '@/assets/img/landing/brokerage_houses.svg';
import reitsIcon from '@/assets/img/landing/reit.svg';
import privateEquityIcon from '@/assets/img/landing/equity.svg';
import applicationIcon from '@/assets/img/landing/Computer.svg';

const industries = [
  {
    Icon: brokerageHousesIcon,
    name: 'CRE Brokerage Houses',
    description: 'Listings provide brokers with a comprehensive view of available properties, helping them connect buyers and sellers efficiently in the competitive real estate market.',
    className: styles.largeCard,
  },
  {
    Icon: municipalitiesIcon,
    name: 'Municipalities and Community Development',
    description: 'Commercial real estate listings aid in planning infrastructure, attracting businesses, and fostering community growth by providing insights into available properties.',
    background: <img src="/path-to-image.jpg" className={styles.backgroundImage} alt="" />,
    className: styles.mediumCard,
  },
  {
    Icon: reitsIcon,
    name: 'REITs',
    description: 'Real estate investment trusts (REITs) use listings to identify investment opportunities and manage property portfolios for long-term growth and income.',
    className: styles.smallCard,
  },
  {
    Icon: privateEquityIcon,
    name: 'Private Equity',
    description: 'Private equity firms leverage listings to locate high-value properties, facilitating strategic investments in commercial real estate to maximize returns.',
    className: styles.largeCard,
  },
  {
    Icon: applicationIcon,
    name: 'CRE Applications & Integration',
    description: 'Leverage Canvassed to integrate your CRE software with your existing systems. Go beyond simple data and unlock new opportunities within your organization.',
    className: styles.largeCard,
  },
];

export function IndustryBentoDemo() {
  return (
    <section id="industries" className={styles.industrySection}>
      <h2 className={styles.sectionTitle}>Serving Key Industries</h2>
      <BentoGrid className={styles.bentoGrid}>
        {industries.map((industry) => (
          <BentoCard 
            key={industry.name} 
            {...industry} 
            Icon={typeof industry.Icon === 'string' ? 
              () => <img src={industry.Icon} alt={`${industry.name} icon`} className={styles.svgIcon} /> : 
              industry.Icon
            }
          />
        ))}
      </BentoGrid>
    </section>
  );
}
