// mapUtils.js

import mapboxgl from 'mapbox-gl';

export const updateListings = (map, listings, savedListings) => {
  console.log("updateListings called with", listings.length, "listings");
  if (!map.getSource('listings')) return;

  console.log("Updating listings...");

  const savedHashIds = new Set(savedListings.map(saved => saved.hash_id));

  const allListings = {
    type: 'FeatureCollection',
    features: listings
      .filter(listing => {
        // Filter out listings that are already saved
        const hashId = listing['Hash ID'] || listing.hash_id;
        if (savedHashIds.has(hashId)) return false;

        // Filter out listings without valid coordinates
        const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
        const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
        return lon && lat && !isNaN(parseFloat(lon)) && !isNaN(parseFloat(lat));
      })
      .map(listing => {
        const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
        const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
        const isSaved = savedHashIds.has(listing.hash_id);
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(lon), parseFloat(lat)]
          },
          properties: {
            hash_id: listing['Hash ID'] || listing.hash_id,
            company_name: listing['Company Name'] || listing.company_name,
            address: listing.Address || listing.address,
            property_type: listing['Property Type'] || listing.property_type,
            transaction_type: listing['Transaction Type'] || listing.transaction_type,
            min_sq_ft_available: listing['Min Sq Ft Available'] || listing.min_sq_ft_available,
            max_sq_ft_available: listing['Max Sq Ft Available'] || listing.max_sq_ft_available,
            broker: listing['Broker'] || listing.broker,
            email: listing['Email'] || listing.email,
            phone: listing['Phone'] || listing.phone,
            brochure_link: listing['Brochure Link'] || listing.brochure_link,
            max_ac_available: listing['Max Acres Available'] || listing.max_ac_available,
            min_ac_available: listing['Min Acres Available'] || listing.min_ac_available,
            link: listing['Link'] || listing.link,
            isSaved: isSaved  
          }
        };
      })
  };

  const savedListingsGeoJSON = {
    type: 'FeatureCollection',
    features: savedListings.map(listing => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)]
      },
      properties: {
        ...listing,
        isSaved: true
      }
    }))
  };

  console.log(`Total valid listings: ${allListings.features.length}`);
  console.log(`Total saved listings: ${savedListingsGeoJSON.features.length}`);

  map.getSource('listings').setData(allListings);

  if (!map.getSource('saved-listings')) {
    map.addSource('saved-listings', {
      type: 'geojson',
      data: savedListingsGeoJSON
    });
  }

  if (!map.getLayer('saved-listings')) {
    map.addLayer({
      id: 'saved-listings',
      type: 'circle',
      source: 'saved-listings',
      paint: {
        'circle-color': '#ff0000',
        'circle-radius': 10,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#ffffff'
      }
    });
  }

  map.getSource('saved-listings').setData(savedListingsGeoJSON);
};

export const fitMapToSearchResults = (map, listings, searchParams) => {
  if (!map || !map.getSource('listings')) {
    console.log("Map or listings source not ready");
    return;
  }

  const validListings = listings.filter(listing => {
    const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
    const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
    return lon && lat && !isNaN(parseFloat(lon)) && !isNaN(parseFloat(lat));
  });

  if (validListings.length === 0) {
    console.log("No valid coordinates in search results");
    return;
  }

  // Check if any geographical search parameters were used
  const hasGeographicSearch = ['county', 'state', 'city', 'zipcode'].some(param => 
    searchParams[param] && searchParams[param].length > 0
  );

  if (hasGeographicSearch) {
    const bounds = new mapboxgl.LngLatBounds();

    validListings.forEach(listing => {
      const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
      const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
      bounds.extend([parseFloat(lon), parseFloat(lat)]);
    });

    map.fitBounds(bounds, {
      padding: 200, // Reduce padding to allow more results in view
      maxZoom: 12, // Increase maxZoom to allow a broader view
      duration: 1000
    });
  }
};

export const updateSingleListing = (map, listing, isSaved) => {
  // Get current data from both sources
  const listingsSource = map.getSource('listings');
  const savedListingsSource = map.getSource('saved-listings');
  
  if (!listingsSource || !savedListingsSource) return;

  // Get current data
  const listingsData = listingsSource.serialize().data;
  const savedListingsData = savedListingsSource.serialize().data;

  if (isSaved) {
    // Remove from listings and add to saved listings
    listingsData.features = listingsData.features.filter(
      feature => feature.properties.hash_id !== listing.hash_id
    );
    savedListingsData.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)]
      },
      properties: {
        ...listing,
        isSaved: true
      }
    });
  } else {
    // Remove from saved listings and add to listings
    savedListingsData.features = savedListingsData.features.filter(
      feature => feature.properties.hash_id !== listing.hash_id
    );
    listingsData.features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)]
      },
      properties: {
        ...listing,
        isSaved: false
      }
    });
  }

  // Update both sources
  listingsSource.setData(listingsData);
  savedListingsSource.setData(savedListingsData);
};
