import { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styles from './SignUp.module.css';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';

const SignUp = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '', // Optional field
  });
  const [error, setError] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [passwordErrors, setPasswordErrors] = useState([]);
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/map');
    }
  }, [isLoggedIn, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Update password validation in real-time
    if (name === 'password') {
      setPasswordErrors(validatePassword(value));
    }
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }
    if (!/[A-Z]/.test(password)) {
      errors.push('Include at least one uppercase letter');
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Include at least one lowercase letter');
    }
    if (!/\d/.test(password)) {
      errors.push('Include at least one number');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Include at least one special character (!@#$%^&*(),.?":{}|<>)');
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordValidationErrors = validatePassword(formData.password);
    
    if (passwordValidationErrors.length > 0) {
      setError('Please fix password requirements: ' + passwordValidationErrors.join(', '));
      return;
    }
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    // Validate required fields
    const requiredFields = ['username', 'email', 'password', 'firstName', 'lastName'];
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0) {
      setError(`Please fill in all required fields: ${missingFields.join(', ')}`);
      return;
    }

    try {
      const response = await axios.post('auth/register', {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        companyName: formData.companyName || undefined,
      });

      console.log(response.data);
      localStorage.setItem('pendingConfirmationEmailOrUsername', formData.username);
      console.log('Registration successful:', response.data);

      // Show confirmation message immediately
      setConfirmationMessage('Account successfully created. We have sent you a confirmation email, please use the link included to confirm your account. \n You will be redirected to the home page shortly.')

      setTimeout(() => {
        navigate('/');
      }, 10000);

      // Redirect to login page or show success message
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during registration');
    }
  };

  if (isLoggedIn) {
    return null; // or a loading spinner if you prefer
  }

  return (
    <>
      <Navbar />
      <Container className={styles.signUpContainer}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <h2 className={styles.signUpTitle}>Sign Up</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {confirmationMessage && <Alert variant="success">{confirmationMessage}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  isInvalid={passwordErrors.length > 0}
                />
                <Form.Text className="text-muted">
                  Password must contain:
                  <ul className="mt-1 mb-0">
                    <li className={formData.password.length >= 8 ? 'text-success' : ''}>
                      At least 8 characters
                    </li>
                    <li className={/[A-Z]/.test(formData.password) ? 'text-success' : ''}>
                      One uppercase letter
                    </li>
                    <li className={/[a-z]/.test(formData.password) ? 'text-success' : ''}>
                      One lowercase letter
                    </li>
                    <li className={/\d/.test(formData.password) ? 'text-success' : ''}>
                      One number
                    </li>
                    <li className={/[!@#$%^&*(),.?":{}|<>]/.test(formData.password) ? 'text-success' : ''}>
                      One special character
                    </li>
                  </ul>
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Company Name or Organization Name (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Form.Group>

              <Button variant="primary" type="submit" className={styles.signUpButton}>
                Sign Up
              </Button>
            </Form>
            <Button variant="primary" type="submit" className={styles.signUpButton}>
                 <Link to="/login">Already have an account? Click to log in</Link>
            </Button>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SignUp;