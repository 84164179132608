import { Container } from 'react-bootstrap';
import styles from './styles.module.css';
import Hero from './Hero';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import WhatIsCanvassed from './WhatIsCanvassed';
import { IndustryBentoDemo } from './bento';
import Features from './Features';
import Services from './Services';
import CountUp from './CountUp';
import SubscriptionCards from './SubscriptionCards';
import { DotPatternDemo } from './dots';
import { useAuth } from '../AuthContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LandingPage = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get('scrollTo');
    
    if (scrollTo === 'what-is-canvassed') {
      const element = document.getElementById('what-is-canvassed');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      // Clean up the URL
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, [location]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.dotPatternWrapper}>
        <DotPatternDemo className={styles.dotPatternDemo} />
      </div>
      <div className={styles.contentWrapper}>
        <Navbar />
        <div className={styles.landingPage}>
          <header className={styles.header}>
            <Container>
            </Container>
          </header>

          <main>
            <Hero />
            <WhatIsCanvassed />
            <Container className={styles.countUpSection}>
              <h2>At a Glance</h2>
              <div className={styles.countUpContainer}>
                <CountUp end={140000} duration={2000} label="Listings+" />
                <CountUp end={50} duration={2000} label="All States Covered" />
                <CountUp end={400} duration={1500} label="Brokerages+" />
              </div>
            </Container>
            <IndustryBentoDemo />
            <Features />
            <Services />
            {!isLoggedIn && <SubscriptionCards />}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
