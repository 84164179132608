import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './SignUpButton.module.css'; // You'll need to create this CSS module

const SignUpButton = () => {
  return (
    <Link to="/signup" className={styles.signUpLink}>
      <Button variant="primary" className={styles.signUpButton}>
        Sign Up
      </Button>
    </Link>
  );
};

export default SignUpButton;
